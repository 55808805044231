import './achievement.css';
import { Accordion } from 'react-bootstrap';
import { KokiaPageTitle } from '../components/kokia-section';

const achieved = (institute, count) =>
    <div className='item' key={institute}>
        <div>{institute}</div><div>{count}名</div>
    </div>

const achievement2024 = () => {
    const tokyo = [
        ['成蹊小学校', 9],
        ['早稲田実業学校初等部', 5],
        ['東京農業大学稲花小学校', 3],
        ['東洋英和女学院小学部', 2],
        ['東京都市大学付属小学校', 2],
        ['立教小学校', 2],
        ['暁星小学校', 1],
        ['聖心女子学院初等部', 1],
        ['桐朋学園小学校', 1],
        ['晃華学園小学校', 1],
        ['成城学園初等学校', 1],
        ['玉川学園小学部', 1],
        ['国立学園小学校', 1],
        ['川村小学校', 1],
        ['聖学院小学校', 1],
        ['トキワ松学園小学校', 1],
        ['洗足学園小学校（内部）', 1],
        ['サレジアン国際学園目黒星美小学校', 1]
    ]

    const kanto = [
        ['西武学園文理小学校', 7],
        ['開智所沢小学校', 2],
        ['横浜雙葉小学校', 1],
        ['浦和ルーテル学院小学校', 1],
        ['開智望小学校', 1],
        ['星野学園小学校', 1],
        ['さとえ学園小学校', 1],
        ['湘南白百合学園小学校', 1],
        ['日の出学園小学校', 1],
        ['捜真小学校', 1],
        ['江戸川学園取手小学校', 1],
        ['慶應義塾横浜初等部', 1],
    ]

    const kansai = [
        ['同志社小学校', 1],
        ['立命館小学校', 1],
    ]

    const kindergarten = [
        ['暁星幼稚園', 1],
        ['同志社幼稚園', 1],
    ]

    return <div className='achievement-list'>
        <div className='region'>東京</div>
        {tokyo.map(([institute, count]) => achieved(institute, count))}

        <div className='region'>神奈川・埼玉・茨城・千葉</div>
        {kanto.map(([institute, count]) => achieved(institute, count))}

        <div className='region'>関西</div>
        {kansai.map(([institute, count]) => achieved(institute, count))}

        <div className='region'>幼稚園</div>
        {kindergarten.map(([institute, count]) => achieved(institute, count))}

        <div className='ach-remark'>
            ※ 願書添削・個別相談・各種講座受講者のみの実績です。
        </div>
    </div>
}

const achievement2023 = () => {
    const tokyo =[
        ['成蹊小学校', 6],
        ['早稲田実業学校初等部', 2],
        ['暁星小学校', 2],
        ['東京農業大学稲花小学校', 2],
        ['筑波大学附属小学校', 2],
        ['聖心女子学院初等部', 1],
        ['横浜雙葉小学校', 1],
        ['桐朋学園小学校', 1],
        ['桐蔭学園小学校', 1],
        ['浦和ルーテル学院小学校', 1],
        ['開智小学校', 1],
        ['開智所沢小学校', 1],
        ['精華小学校', 1],
        ['学習院初等科', 1],
    ]

    return <div className='achievement-list'>

        {tokyo.map(([institute, count]) => achieved(institute, count))}

        <div className='ach-remark'>
            ※ 願書添削・個別相談・各種講座受講者のみの実績です。
        </div>
    </div>
}

function Achievements() {
    return (
        <>
            <KokiaPageTitle label='合格実績' />
            <Accordion>
                <Accordion.Item eventKey='2024' key='2024' >
                    <Accordion.Header>
                        2024年秋
                    </Accordion.Header>
                    <Accordion.Body>
                        { achievement2024() }
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='2023' key='2023'>
                    <Accordion.Header>
                        2023年秋
                    </Accordion.Header>
                    <Accordion.Body>
                        { achievement2023() }
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    );
}

export default Achievements;